<template>
  <v-container id="dashboard" fluid tag="section"> </v-container>
</template>

<script>
export default {
  name: "NewUser",
  mounted() {
    this.$router.push({ name: "Dashbord" });
  },
};
</script>
